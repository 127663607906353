
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { getConfigEnv } from '@/utils/helpers';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import userPreferenceDB from '@/lib/userPreference';
import axiosClient from '@/lib/rest/axiosClient';

@Component
export default class ChartHeaderComponent extends Vue {
  @Prop({ type: String, required: false, default: '' }) title?: any;
  @Prop({ type: String, required: false, default: '' }) relabelKey?: any;
  @Prop({ type: Boolean, required: false, default: false }) isFullScreen?: any;
  @Prop({ type: String, required: false, default: '' }) event?: any;
  @Prop({ type: Boolean, default: false }) hideFullScreen!: boolean;
  @Prop({ type: Boolean, default: false }) showDataRelabel: boolean;
  @Prop({ type: Boolean, default: false }) isSignalHighlightSelected: boolean;
  @Prop({ type: Boolean, default: false }) hideFullscreenBtn: boolean;
  @Prop({ type: Boolean, default: false }) hideRemoveTarget: boolean;
  @Prop({ type: Array, default: () => [] }) legendSignals: any;
  @Prop({ type: Boolean, required: false, default: false })
  isBulkTestWell?: any;
  @Prop({ type: String, default: '' }) type: any;

  headers = ['selected', 'Tasq Name'];
  columns = [
    { key: 'selected', editable: false },
    { key: 'tasq_name', editable: false },
  ];

  showSelectedSignals = true;

  signalSearchQuery = '';

  updateTableDataCurrentSignals() {
    this.tableData = this.legendSignals
      .map((signal) => ({
        selected: this.allSelectedSingalsOnLegends.includes(
          signal.name.toLowerCase()
        ),
        tasq_name: signal.name,
        color: signal.color,
      }))
      .sort((a, b) => a.tasq_name.localeCompare(b.tasq_name));
  }

  @Watch('legendSignals', { immediate: true })
  updateCurrentSignals() {
    this.updateTableDataCurrentSignals();
  }

  allSelectedSingalsOnLegends: any = [];

  async created() {
    await this.getSelectedSignals();
  }

  async getSelectedSignals() {
    const res: any = await userPreferenceDB.getItem(`${this.type}Selected`);
    if (res && res.length > 0) {
      this.allSelectedSingalsOnLegends = res;
    }
  }

  async handleClick(index, name) {
    let removeFromList = false;
    if (this.allSelectedSingalsOnLegends.includes(name.toLowerCase())) {
      this.allSelectedSingalsOnLegends =
        this.allSelectedSingalsOnLegends.filter(
          (item) => item !== name.toLowerCase()
        );
      removeFromList = true;
    } else {
      this.allSelectedSingalsOnLegends.push(name.toLowerCase());
    }
    await userPreferenceDB.setItem(
      `${this.type}Selected`,
      this.allSelectedSingalsOnLegends
    );

    this.$emit('select-signal-on-legend', {
      name,
      index,
      type: this.type,
      removeEvent: removeFromList,
    });

    // this.showSelectedSignals = false
    this.updateTableDataCurrentSignals();
    this.$nextTick(() => {
      this.showSelectedSignals = true;
    });
  }

  get selectedSignals() {
    return this.tableData.filter((item) => item.selected);
  }

  tableData: any = [];

  handleFullScreenClick() {
    this.$emit('toggle-fullscreen');
  }

  handleResetClick() {
    this.$emit('reset-chart', this.event);
  }

  get allSignalsNotSelected() {
    return this.tableData.some((item) => item.selected === false);
  }

  @Watch('signalSearchQuery')
  onSearchQueryChange() {
    this.tableData = this.legendSignals
      .filter((signal) =>
        signal.name.toLowerCase().includes(this.signalSearchQuery.toLowerCase())
      )
      .map((signal) => ({
        selected: this.allSelectedSingalsOnLegends.includes(
          signal.name.toLowerCase()
        ),
        tasq_name: signal.name,
        color: signal.color,
      }))
      .sort((a, b) => a.tasq_name.localeCompare(b.tasq_name));
  }

  get availableSelectedSignals() {
    return this.legendSignals.filter((s) =>
      this.allSelectedSingalsOnLegends.includes(s.name.toLowerCase())
    ).length;
  }

  updateSelection() {
    const update = this.allSignalsNotSelected;
    this.tableData.forEach((item, index) => {
      if (item.selected !== update) {
        item.selected = update;
        this.handleClick(index, item.tasq_name);
      }
    });
  }





  getCurrentSignalNameForLegend(signal_name) {
    if (signal_name == 'gas_rate') {
      if (this.isBulkTestWell) {
        return 'Allocated Gas Rate';
      }
      return 'Gas Rate';
    }
    if (signal_name == 'oil_rate') {
      if (this.isBulkTestWell) {
        return 'Allocated Oil Rate';
      }
      return 'Oil Rate';
    }
    if (signal_name == 'water_rate') {
      return 'Water Rate';
    }
    if (signal_name == 'boe_rate') {
      if (this.isBulkTestWell) {
        return 'Allocated BOE Rate';
      }
      return 'BOE Rate';
    }
    if (signal_name == 'water_rate_fc') {
      return 'Water Rate FC';
    }
    if (signal_name == 'water_rate_source') {
      return 'Water Rate Source';
    }
    if (signal_name == 'gas_rate_fc') {
      return 'Gas Rate FC';
    }
    if (signal_name == 'oil_rate_fc') {
      return 'Oil Rate FC';
    }
    if (signal_name == 'gas_rate_source') {
      return 'Gas Rate Source';
    }
    if (signal_name == 'boe_rate_predicted') {
      return 'Boe Rate (Model Predicted)';
    }
    if (signal_name == 'oil_rate_source') {
      return 'Oil Rate Source';
    }
    if (signal_name == 'gas_rate_predicted') {
      return 'Gas Rate (Model Predicted)';
    }
    if (signal_name == 'well_test_gas') {
      return 'Well Test Gas';
    }
    if (signal_name == 'Flowrate (Predicted v2)') {
      return 'Flowrate Predicted';
    }
    return signal_name;
  }
}
